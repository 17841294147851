import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { observer } from '@ember/object';

export default Service.extend({
    requestService: service(),
    headlessService:  service(),
    darkMode: true,
    showReportFilter: true,
    orgRoleBasedPermissions: "unavailable",
    openreporttreenodes:[],

    init(){
        this._super(...arguments);

        this.set("timezone", moment.tz.guess());

        this.getValue("specifictimezone").then((specificTimeZone) =>{
            let isSpecific = specificTimeZone.value == "true";
            this.set("specificTimeZone", isSpecific);

            if(isSpecific){
                this.getValue("timezone").then((timezone) =>{
                    if(timezone.value === ""){
                        timezone.value = moment.tz.guess();

                        this.setValue("timezone", timezone.value);
                    }
                    this.set("timezone", timezone.value);
                });
            } else{
                this.set("timezone", moment.tz.guess());
            }
        });

        if(this.headlessService.isPrintView()){
            //Print and dashboard views don't care about these settings
            return;
        }

        this.getValue("darkmode").then((darkmode) =>{
            this.set("darkMode", darkmode.value !== "false");
        });

        this.getValue("collapsedsidebar").then((collapsedsidebar) =>{
            this.set("collapsedsidebar", collapsedsidebar.value === "true");
        });

        this.getValue("showreportfilter").then((showReportFilter) =>{
            this.set("showReportFilter", showReportFilter.value !== "false");
        });

        this.requestService.getResource("/config/rolebasedpermissions/state").then((value)=>{
            this.set("orgRoleBasedPermissions", value.value);
        })

        this.getValue("openreporttreenodes").then((openreporttreenodes) =>{
            if(openreporttreenodes.value.length > 0){
                this.set("openreporttreenodes", JSON.parse(openreporttreenodes.value));
            }
        });

    },
    enableRoleBasedPermissions(){
        return this.requestService.postResource("/config/organization/setting/rolebasedpermissions", {
            value: "true"
        }).then(()=>{
            this.set("orgRoleBasedPermissions", "setup")
        });
    },

    darkModeChanged: observer('darkMode', function() {
        this.setValue("darkmode", JSON.stringify(this.darkMode));
    }),

    showReportFilterChanged: observer('showReportFilter', function() {
        this.setValue("showreportfilter", JSON.stringify(this.showReportFilter));
    }),

    timezoneChanged: observer('timezone', function() {
        this.setValue("timezone", this.timezone);
    }),

    specificTimeZoneChanged: observer('specificTimeZone', function() {
        this.setValue("specifictimezone", JSON.stringify(this.specificTimeZone));
    }),

    setValue(key, value){
        return this.requestService.postResource("/config/user/setting/" + key, {
            value
        });
    },

    getValue(key){
        return this.requestService.getResource("/config/user/setting/" + key);
    },

    toggleCollapsedSidebar() {
      const current = this.collapsedsidebar;

      this.setValue('collapsedsidebar', (!current).toString())
      this.set('collapsedsidebar', !current)

      return !current
    },

    sidebarReportFolderIsCollapsed(category, path, isCollapsed){
        this.openreporttreenodes.removeObject(category + path);

        if(!isCollapsed) {
            this.openreporttreenodes.pushObject(category + path);
        }

        this.setValue("openreporttreenodes", JSON.stringify(this.openreporttreenodes));
    },
    isReportFolderCollapsed(category, fullPath){
        if(!fullPath){
            return false;
        }

        return this.openreporttreenodes.indexOf(category + fullPath) === -1;
    },
    collapseAllFolders(){
        this.openreporttreenodes.clear();
        this.setValue("openreporttreenodes", JSON.stringify(this.openreporttreenodes));
    }
});
