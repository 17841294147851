import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("\n<ul class='rb-list number-filter-list'>\n    <li>\n        <RadioButton @value=\"true\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>True</label>            \n        </RadioButton>\n    </li>\n    <li>\n        <RadioButton @value=\"false\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>False</label>            \n        </RadioButton>\n    </li>\n    <li>\n        <RadioButton @value=\"any\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>Any</label>\n        </RadioButton>\n    </li>   \n</ul>\n", {"contents":"\n<ul class='rb-list number-filter-list'>\n    <li>\n        <RadioButton @value=\"true\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>True</label>            \n        </RadioButton>\n    </li>\n    <li>\n        <RadioButton @value=\"false\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>False</label>            \n        </RadioButton>\n    </li>\n    <li>\n        <RadioButton @value=\"any\" @groupValue={{filterValue}} @changed={{action \"selectedValueChanged\"}}>\n            <label>Any</label>\n        </RadioButton>\n    </li>   \n</ul>\n","moduleName":"bi/components/filter/filter-boolean-editor.hbs","parseOptions":{"srcName":"bi/components/filter/filter-boolean-editor.hbs"}});
import Component from '@ember/component';
import { observer, set } from '@ember/object';

export default Component.extend({
    isTrue: false,
    
    didReceiveAttrs() {
        this._super(...arguments);
        let filter = this.filter;
        
        if(!filter){
            this.calculateFilter();        
            return;
        }
        
        
        this.set("filterValue", "any");

        if(filter.values && filter.values.length > 0){
            if(filter.values[0] === true){
                this.set("filterValue", "true");
            } else if(filter.values[0] === false){
                this.set("filterValue", "false");
            }            
        }

        if(!filter.operator){
            this.calculateFilter();        
            return;
        }
        
    },

    calculateFilter(){
        
        let filter = this.filter;

        let value = this.filterValue;

        if(value == "true"){
            value = true
        } else if(value == "false"){
            value = false
        }

        let values = [
            value
        ];
        
        set(filter, "values", values);
        set(filter, "operator", "=");
        
        this.set("filter", filter);
    },
    filterValueChanged: observer('filterValue', function() {
        this.calculateFilter();
    }),
    actions:{
        selectedValueChanged(val){

            this.set('filterValue', val);
            this.calculateFilter();
        } 
    }
   
});
