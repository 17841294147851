let keywords = ['ALTER',
'AND',
'AS',
'BETWEEN',
'BY',
'CASE',
'CAST',
'CONSTRAINT',
'CREATE',
'CROSS',
'CUBE',
'CURRENT_DATE',
'CURRENT_TIME',
'CURRENT_TIMESTAMP',
'CURRENT_USER',
'DEALLOCATE',
'DELETE',
'DESCRIBE',
'DISTINCT',
'DROP',
'ELSE',
'END',
'ESCAPE',
'EXCEPT',
'EXECUTE',
'EXISTS',
'EXTRACT',
'FALSE',
'FOR',
'FROM',
'FULL',
'GROUP',
'GROUPING',
'HAVING',
'IN',
'INNER',
'INSERT',
'INTERSECT',
'INTO',
'IS',
'JOIN',
'LEFT',
'LIKE',
'LOCALTIME',
'LOCALTIMESTAMP',
'NATURAL',
'NORMALIZE',
'NOT',
'NULL',
'ON',
'OR',
'ORDER',
'OUTER',
'PREPARE',
'RECURSIVE',
'RIGHT',
'ROLLUP',
'SELECT',
'TABLE',
'THEN',
'TRUE',
'UESCAPE',
'UNION',
'UNNEST',
'USING',
'VALUES',
'WHEN',
'WHERE',
'WITH'];

function isReservedKeyword(text){
    if(!text){
        return false;
    }

    return keywords.indexOf(text.toUpperCase()) > -1;
    
}

export default isReservedKeyword;
