import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { observer } from '@ember/object';
import { computed } from '@ember/object';

export default Controller.extend({
  router:  service(),
  requestService:  service(),
  configService:  service(),
  headlessService:  service(),
  embeddedService:  service(),
  settingsService: service(),
  queryCache: service(),
  isLoading: true,
  isHeadlessOrPrint: computed('router.currentURL', function() {
    return this.headlessService.isHeadless()   || this.headlessService.isPrintView();
  }),
  init(){
    this._super(...arguments);

    if(this.headlessService.isHeadless() || this.headlessService.isPrintView() ){
      this.set("isLoading", false);
    } else{
      let self = this;
      this.requestService.getResource("/config").then(function(config){
        //debugger;
        self.set("isLoading", false);
        self.get("configService").setConfig(config);
      });
    }

    this.set('isEmbedded', this.embeddedService.isEmbedded());
    this.set("isDarkMode", this.settingsService.darkMode);
    this.queryCache.start();
  },
  valuesChanged: observer('settingsService.darkMode', function() {
    this.set("isDarkMode", this.settingsService.darkMode);
  }),
});
