import textformatter from './textformatter';

function pivot(executeResult, visual) {

  let aggregateColumnCount = 0;
  let newColumnDefinitions = [];

  if(executeResult.columnInfo.length != 3){
      throw "Pivot table only supports 2 groups and 1 value"
  }

  executeResult.columnInfo.forEach((col) => {
    if (col.type !== "varchar" || col.type !== "string") {
      aggregateColumnCount = aggregateColumnCount + 1;
    }
  });

  newColumnDefinitions.pushObject(executeResult.columnInfo);

  let nonaggregateColumns = executeResult.columnInfo.length - aggregateColumnCount;

  if (aggregateColumnCount === 0 || nonaggregateColumns === 1) {
    return executeResult;
  }

  let newColumns = []

  let newDataObjects = {};
 

  let newResults = {
    columnInfo: executeResult.columnInfo.slice(0, 1),
    results: [],
  }

  executeResult.results.forEach((row) => {
    if (newColumns.indexOf(row[1]) === -1) {
      newColumns.pushObject(row[1]);

      newResults.columnInfo.push({
        name: row[1],
        label: row[1],
        type: executeResult.columnInfo[2].type,
        format: executeResult.columnInfo[2].format,
      });
    }

    if (!newDataObjects[row[0]]) {
      newDataObjects[row[0]] = {};
    }
    newDataObjects[row[0]][row[1]] = row[2];

  });


  newColumns = newColumns.sort((a,b) => {
    var nameA = a.toUpperCase(); // ignore upper and lowercase
    var nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  //resort columns
  var first = newResults.columnInfo[0];
  newResults.columnInfo.shift();
  newResults.columnInfo.sort((a,b) => {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  newResults.columnInfo.unshift(first);

  let valueFormat = null;

  if(visual.values && visual.values.length > 0 && visual.values[0].format !== ""){
    valueFormat = visual.values[0].format;
  }

  Object.keys(newDataObjects).forEach((key) => {
    let rowObject = newDataObjects[key];

    let row = [key];

    for (let x = 0; x < newColumns.length; x++) {
      let col = newColumns[x];
      
      if (rowObject[col]) {
          if(valueFormat){
            row.pushObject(textformatter.format(rowObject[col], valueFormat));
          }else{
            row.pushObject(rowObject[col]);
          }
        
      } else {
        if(valueFormat){
            row.pushObject(textformatter.format(0, valueFormat));
          }else{
            row.pushObject(0);
          }
        
      }
    }

    newResults.results.pushObject(row);

  });

  return newResults;
}

export default pivot;
