/* global window */ 
import Service from '@ember/service';

export default Service.extend({
    isHeadless(){
        return (window.location.href.indexOf("headless") > -1) ;
    },

    isPrintView(){
        return (window.location.href.indexOf("print") > -1 || window.location.href.indexOf("wallboard/") > -1);
    }
   
});
