import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if isPrintView}}\n<img src={{imgsrc}} width='100%' height='100%'>\n{{else}}\n<div class=\"chart\" style=\"width: 100%;height:{{height}}; \"></div>\n{{/if}}\n\n\n", {"contents":"{{#if isPrintView}}\n<img src={{imgsrc}} width='100%' height='100%'>\n{{else}}\n<div class=\"chart\" style=\"width: 100%;height:{{height}}; \"></div>\n{{/if}}\n\n\n","moduleName":"bi/components/report/treemap-visual.hbs","parseOptions":{"srcName":"bi/components/report/treemap-visual.hbs"}});
/* global $ */
import Component from '@ember/component';

import EchartMixin from '../../mixins/echart-mixin';

export default Component.extend(EchartMixin, {
    
    getOptions(){
       
        let data = this.data;
        let visual = this.visual;

        if(!data || !visual){
            return 
        }

        let groups = [];

        for(let x=0; x< visual.groups.length; x++){
            groups.pushObject(data.columnInfo[x].label);
        }

        let datamap = {};

        if(groups.length === 0){
            datamap['Data'] = {
                name: 'Data',
                value: 0                
            }
        }

        data.results.forEach((row) => {
            // debugger;
            let valueIndex = row.length - 1;
            if(groups.length === 0){
                datamap['Data'].value = datamap['Data'].value + parseInt(row[valueIndex])
            }else {
                let subGroupMap = datamap;
                for(let x = 0; x < groups.length; x++){
                    if(!subGroupMap[row[x]]){
                        subGroupMap[row[x]] = {
                            name: row[x],
                            value: 0 ,
                            childrenMap: {}               
                        }
                    }
                    subGroupMap = subGroupMap[row[x]];
                    subGroupMap.value = subGroupMap.value + parseInt(row[valueIndex]);
                    subGroupMap = subGroupMap.childrenMap;
                }                
            }
            
        });

        let seriesData = [];

        Object.keys(datamap).forEach((k)=>{
            let group = datamap[k];


            seriesData.pushObject({
                label: {
                    show: true,
                    formatter: '{b}'
                  },
                //   upperLabel: {
                //     show: true,
                //     height: 30
                //   },
                name: group.name,
                value: group.value,
                children: this.getChildrenData(group.name, group.childrenMap),
            });
        })

    
        let options = {
            series: [ {
                type: 'treemap',
                leafDepth: groups.length,
            
                data: seriesData,
         
            } ]
        };

        return options;
    },
    getChildrenData(parentName, childrenMap){
        let children = [];

        if(childrenMap){
            Object.keys(childrenMap).forEach((k)=>{
                let group = childrenMap[k];
                
                let name = group.name; //parentName + " " + group.name;
                children.pushObject({
                    name: name,
                    value: group.value,
                    children: this.getChildrenData(name, group.childrenMap),
                    label: {
                        show: true,
                        formatter: '{b}'
                      },
                    //   upperLabel: {
                    //     show: true,
                    //     height: 30
                    //   },
                });
            });
        }
    

        return children;
    }
});

