import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
    reportService: service(),
    
    model(params) {
        return new resolve(params.headless_id);
    }
    
});
