let ChartColors = [
    "#084081",
    "#2B8CBE",
    "#80CDC1",
    "#BF812D",
    "#543005",
    "#0868AC",
    "#4EB3D3",
    "#C7EAE5",
    "#DFC27D",
    "#8C510A",
    "#38200D"
   ];

//Tableau Palette
ChartColors = [
    "#0078BB",
    "#81BBE5",
    "#9DCF85",
    "#153A64",
    "#BFBFBF",
    "#39B44A",
    "#9DCF85",
    "#333333",
    "#808080", 
    "#8c564b", 
    "#c5b0d5", 
    "#9467bd", 
    "#ff9896", 
    "#d62728", 
    "#98df8a", 
    "#2ca02c", 
    "#ffbb78", 
    "#ff7f0e", 
    "#aec7e8", 
    "#1f77b4"
   ];


let colors = ChartColors.concat(ChartColors).concat(ChartColors);

export default colors;


//was 4   "#F6E8C3",