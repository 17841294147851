import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs(" {{!-- <SelectList @class=\"form-control\" @content={{datasets}} @optionValuePath=\"id\" @optionLabelPath=\"name\" @value={{selectedDataSet}} @action={{action (mut selectedDataSet)}} /> --}}\n\n<PowerSelect @class=\"field-extra-options\" @triggerId=\"dataset-selector\" @options={{datasets}} @selected={{selectedDataSet}} @searchField=\"name\"  @searchEnabled={{true}} @onChange={{action (mut selectedDataSet)}} as |ds|>\n    {{ds.name}}\n</PowerSelect>      ", {"contents":" {{!-- <SelectList @class=\"form-control\" @content={{datasets}} @optionValuePath=\"id\" @optionLabelPath=\"name\" @value={{selectedDataSet}} @action={{action (mut selectedDataSet)}} /> --}}\n\n<PowerSelect @class=\"field-extra-options\" @triggerId=\"dataset-selector\" @options={{datasets}} @selected={{selectedDataSet}} @searchField=\"name\"  @searchEnabled={{true}} @onChange={{action (mut selectedDataSet)}} as |ds|>\n    {{ds.name}}\n</PowerSelect>      ","moduleName":"bi/components/dataset-selector.hbs","parseOptions":{"srcName":"bi/components/dataset-selector.hbs"}});
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
    configService: service(),
    datasets:[],
    selectedDataSet: null,
    init() {
        this._super(...arguments);
        this.set("selectedDataSet", this.configService.dataSets.findBy("id",this.configService.get("currentdatasourceId") ));

        this.datasets.setObjects(this.configService.dataSets);

        this.selectedDataSet;

    },
    // configServiceValueChanged: observer('configService.currentdatasourceId', function() {
    //     let currentDataSet = this.configService.get("currentdatasourceId");

    //     if(currentDataSet){
    //         this.set("selectedDataSet", currentDataSet);
    //     }
    // }),
    configDataSetsChanged: observer('configService.dataSets', function() {
        this.datasets.setObjects(this.configService.dataSets);
        this.set("selectedDataSet", this.configService.dataSets.findBy("id",this.configService.get("currentdatasourceId") ));
    }),

    selectedValueChanged: observer('selectedDataSet', function() {
        this.configService.set("currentdatasourceId", this.selectedDataSet.id);
    }),
});
