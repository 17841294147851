import { helper as buildHelper } from '@ember/component/helper';
import textformatter from '../utils/textformatter';

export function menuDirection([visual]) {
  try{
    if(visual.location && ((visual.location.width <= 2 && visual.location.x <=2) || (visual.location.height <= 2))) {
      return "dropdown-menu-left";
    }
  }catch(ex){
    //noop
    console.error(ex);
  }
  

  return "dropdown-menu-right";
}

export default buildHelper(menuDirection);