import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full visual-view'  >\n    <div class='row' style='height:100%'>\n    <div class='col' style='height:100%'>\n      <div class=\"info-visual\" style={{visualStyle}}>\n      {{markdown-to-html \n         markdown=visual.displayProperties.text}}\n      </div>\n    </div>\n  </div>\n\n</div>\n", {"contents":"<div class='container-full visual-view'  >\n    <div class='row' style='height:100%'>\n    <div class='col' style='height:100%'>\n      <div class=\"info-visual\" style={{visualStyle}}>\n      {{markdown-to-html \n         markdown=visual.displayProperties.text}}\n      </div>\n    </div>\n  </div>\n\n</div>\n","moduleName":"bi/components/report/info-visual.hbs","parseOptions":{"srcName":"bi/components/report/info-visual.hbs"}});
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Component from '@ember/component';
import textformatter from '../../utils/textformatter';

export default Component.extend({
    fontSize: 24,
    visualStyle: computed('fontSize', 'fontColor', function() {
        return htmlSafe('font-size: ' + this.fontSize + 'px;color: ' + this.fontColor + ';');
    }),
    didReceiveAttrs() {
        this._super(...arguments);

        if (!this.visual.displayProperties) {
            return;
        }

        if(this.visual.displayProperties && this.visual.displayProperties.fontSize){
            this.set("fontSize", parseInt(this.visual.displayProperties.fontSize));
        }else {
            this.set("fontSize", 24);
        }
        
        if(this.visual.displayProperties && this.visual.displayProperties.fontColor){
            this.set("fontColor", this.visual.displayProperties.fontColor);
        }else {
            this.set("fontColor", black);
        }


    }
});
