import Evented from '@ember/object/evented';
import Service from '@ember/service';
import Ember from 'ember';

export default Service.extend(Evented,{
    localStorageSet(key, value){
        try{
            if (typeof window.localStorage !== 'undefined') {
                window.localStorage[key] = JSON.stringify(value);
            }
        }catch(ex){
            Ember.Logger.warn(ex);
        }
    },
    localStorageGet(key){
        try{
            if (typeof window.localStorage !== 'undefined') {
                return JSON.parse(window.localStorage[key]);
            }
        }catch(ex){
           //noop
        }

        return null;
    },
    sessionStorageSet(key, value){
        try{
            if (typeof window.sessionStorage !== 'undefined') {
                window.sessionStorage[key] = JSON.stringify(value);
            }
        }catch(ex){
            Ember.Logger.warn(ex);
        }
    },
    sessionStorageGet(key){
        try{
            if (typeof window.sessionStorage !== 'undefined') {
                return JSON.parse(window.sessionStorage[key]);
            }
        }catch(ex){
           //noop
        }

        return null;
    }
});
