import Service from '@ember/service';

export default Service.extend({
  isEmbedded(){
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }
});
