function adapt(queryResponse){
    let tableData = {
        columns: [],
        rows: []
    };

    queryResponse.columnInfo.forEach((col)=>{
        let colDef = {
            name: col.label,
            valuePath: col.name,
            width:50
        };

        adjustWidth(colDef, col.label);

        tableData.columns.push(colDef);
    });

    queryResponse.results.forEach((row)=>{

        let rowObject = {};

        for(let x=0; x<row.length; x++){
            let col = tableData.columns[x].valuePath;
            rowObject[col] = row[x];
            adjustWidth(tableData.columns[x], row[x]);
        }

        tableData.rows.push(rowObject);
    });

    return tableData;
}

function adjustWidth(column, data){
    let currentWidth = column.width;

    let newWidth = data.length * 9;

    if(currentWidth < newWidth){
        column.width = newWidth;
    }
}

export default adapt;