/* global Promise */
import { observer } from '@ember/object';

import Service, { inject as service } from '@ember/service';
import textformatter from '../utils/textformatter';

const SELECTED_DATA_SOURCE_ID_KEY = "SelectedDataSource";

export default Service.extend({
    requestService: service(),
    storageService: service(),
    queryCache: service(),
    me: {},
    dataSets:[],
    datasourcedefinition:[],
    datasourcedefinitionMap:[],
    currentdatasourceId:"",
    tablevalueCache:{},
    databaseTables:{},
    availableDatabases:[],
    databaseCapabilities:{},
    isMobileDevice() {
        const mobileUserAgents = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return mobileUserAgents.some((uaItem) => {
            return navigator.userAgent.match(uaItem);
        });
    },
    setConfig(config){

        this.getTables();
        this.set('me', config.me);

        if(config.me.organization.id == "e89cee8c-24d8-43ad-9464-520f6b20fa1f" && this.isMobileDevice()){
            window.location.replace("/unauthorized.html?message=" + encodeURI("PureInsights users are not allowed to use PIBI from a mobile browser.  This is due to ISO 27001 requirements."));
            return;
        } 
        
        this.set('dataSets', config.customers.sort((a,b)=>{
            return a.name.localeCompare(b.name);
        }));

        if (config.customers[0] === null){
            window.location.replace("/server/premiumapp/setup");
        }
        
        let dataSourceId = this.storageService.localStorageGet(SELECTED_DATA_SOURCE_ID_KEY);
        
        let datasource = config.customers.findBy("id", dataSourceId);

        if(datasource){
            this.set("currentdatasourceId", dataSourceId);
            this.set("currentOrgName", datasource.name);
        }else{
            this.set("currentdatasourceId", config.customers[0].id);
            this.set("currentOrgName", config.customers[0].name);
        }

        this.getDatabases().forEach((db)=>{
            this.databaseCapabilities[db.name] = db.capabilities;
        });

        this.getTables();
        this.getDatabases().forEach((db)=>{
            this.databaseCapabilities[db.name] = db.capabilities;
        });

    },
    getDatabases(){
        if(!this.currentdatasourceId){
            return [];            
        }

        let customer = this.dataSets.findBy("id", this.currentdatasourceId);

        return customer.databases;
    },
    getTables(){
        if(!this.currentdatasourceId){
            return;            
        }

        this.getDatabases().forEach((db)=>{
            this.queryCache.getResource(`/describedatasource/${this.currentdatasourceId}/${db.name}`  ).then((resp)=>{
                this.databaseTables[db.name] = resp;
                this.availableDatabases.pushObject(db.Name);
            }).catch((ex)=>{
                console.error(ex);
            });
    
        })
        

        this.queryCache.getResource(`/describedatasource/${this.currentdatasourceId}`  ).then((resp)=>{
            this.set("datasourcedefinition", Object.values(resp));
            this.datasourcedefinition.forEach((source)=>{
                source.displayname = textformatter.format(source.name).replace("userq"," User Q");

                source.columns.forEach((col)=>{
                    if(col.type === "datetime"){
                        col.type === "timestamp";
                    }
                })
            });
            this.set("datasourcedefinitionMap", resp);
        }).catch(()=>{
            this.set("dbsyncNotCompleted", true);
        });
    },
    getDatasourceDefinitionMapForDatabase(database){
        return this.databaseTables[database];
    },
    selectedValueChanged: observer('currentdatasourceId', function() {
        this.getTables();

        this.storageService.localStorageSet(SELECTED_DATA_SOURCE_ID_KEY, this.currentdatasourceId);
    })
});
