import Service from '@ember/service';

export default Service.extend({
  isHidden:false,
  init(){
    this._super(...arguments);

    let self = this;
    document.addEventListener("visibilitychange", () => {
      self.set("isHidden", document.visibilityState === "hidden");
    });
  }  
});
