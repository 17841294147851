import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full'>\n    <div class='row'>\n        <div class='col-12' >\n            <EmberAce @lines={{lines}} @value={{sql}} @mode=\"ace/mode/sql\" @theme=\"ace/theme/github\" @update={{action \"valueUpdated\"}} @enableLiveAutocompletion={{true}} @enableDefaultAutocompletion={{false}} @suggestCompletions={{action \"suggestCompletions\"}} />\n        </div>\n    </div>\n</div>", {"contents":"<div class='container-full'>\n    <div class='row'>\n        <div class='col-12' >\n            <EmberAce @lines={{lines}} @value={{sql}} @mode=\"ace/mode/sql\" @theme=\"ace/theme/github\" @update={{action \"valueUpdated\"}} @enableLiveAutocompletion={{true}} @enableDefaultAutocompletion={{false}} @suggestCompletions={{action \"suggestCompletions\"}} />\n        </div>\n    </div>\n</div>","moduleName":"bi/components/editor/raw-sql.hbs","parseOptions":{"srcName":"bi/components/editor/raw-sql.hbs"}});
import { set } from '@ember/object';
import Component from '@ember/component';

import autocomplete from '../../utils/autocomplete';

export default Component.extend({
    lines:20,
    actions:{
        valueUpdated(value){
            set(this,"sql", value);
            
        },
        suggestCompletions(editor, session, position, prefix){
            
            let suggests = [];
            // this.columns.forEach((col)=>{
            //     let name = col.name || col.column;
            //     let prefixIndex = name.toLowerCase().indexOf(prefix.toLowerCase());
            //     if(prefixIndex > -1 ){
            //         let score = prefix.length - prefixIndex;
            //         suggests.push({
            //             value: name,
            //             snippet: name,
            //             score: score
            //         });
            //     }
            // });

            autocomplete.forEach((op)=>{
                let prefixIndex = op.type.toLowerCase().indexOf(prefix.toLowerCase());

                if(prefixIndex === 0){
                    let score = prefix.length - prefixIndex;
                    suggests.push({
                        value: op.type,
                        snippet: op.value,
                        score: score,
                    });
                }
            });
        
            return suggests;
        }
    }
});
