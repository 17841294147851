import { resolve } from 'rsvp';
import Route from '@ember/routing/route';

export default Route.extend({
    model(params, transition) {
        
        return new resolve({
            key: params['key'],
        });
    }
});
