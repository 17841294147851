import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default Service.extend({
    notifications: service(),
    success(message){
        this.notifications.success(message, {
            autoClear: true
          });
          
        console.log(message);
    },
    error(message, autoClear = true){
        this.notifications.error(message, {
            autoClear: autoClear
          });


        console.error(message);
    },
    warning(message){
        this.notifications.warning(message, {
            autoClear: true
          });


        if(console.warning){
            console.warning(message);
        }
        
    },
    info(message){
        this.notifications.info(message, {
            autoClear: true
          });

        console.log(message);
    }
});
