import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full'>\n\n    <div class='row'>\n        <div class='col form-inline'>\n             <Input class=\"form-check-input\" @type=\"checkbox\" @name=\"showGrandTotal\" @id=\"showGrandTotal\" @checked={{showGrandTotal}} />\n            <label for='showGrandTotal' >Show Grand Total</label> \n    \n        </div>\n    </div>\n   \n\n</div>\n", {"contents":"<div class='container-full'>\n\n    <div class='row'>\n        <div class='col form-inline'>\n             <Input class=\"form-check-input\" @type=\"checkbox\" @name=\"showGrandTotal\" @id=\"showGrandTotal\" @checked={{showGrandTotal}} />\n            <label for='showGrandTotal' >Show Grand Total</label> \n    \n        </div>\n    </div>\n   \n\n</div>\n","moduleName":"bi/components/editor/table-options.hbs","parseOptions":{"srcName":"bi/components/editor/table-options.hbs"}});
import { observer, set } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
    didReceiveAttrs() {
        this._super(...arguments);

        let properties  = this.visual.displayProperties;

        if(properties.showGrandTotal){
            this.set("showGrandTotal", properties.showGrandTotal === "true");
        }
    },
    selectedVisualTypeChanged: observer('showGrandTotal', function() {
        let properties  = this.visual.displayProperties;

        let formatVal = this.showGrandTotal;
        set(properties, "showGrandTotal", `${formatVal}`);

    }),
});
