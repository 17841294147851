/*global moment*/

import { observer } from '@ember/object';

import { later } from '@ember/runloop';
import Mixin from '@ember/object/mixin';


import * as echarts from 'echarts';
import 'echarts-wordcloud';


export default Mixin.create({
    height:"100%",
    drawChart(){
        let options = this.getOptions();

        later(()=>{
            try{
                if(this.isPrintView){
                    let chartDiv = this.$().find("img")[0];
                    let myChart = echarts.init(chartDiv);
                    myChart.setOption(options);
                    
                    myChart.resize();
                    var data = myChart.getConnectedDataURL({
                        pixelRatio: 2,
                        excludeComponents:  ['toolbox'],
                    });
                    // console.log(data);
                    this.set("imgsrc", data);
        
                   
                }else{
                    let chartDiv = this.$().find("div")[0];
                    let myChart = echarts.init(chartDiv);
            
                    myChart.setOption(options);
                    myChart.resize();
            
                }
            }catch(ex){
                console.error(ex);
            }
            
        },250);
    },  
    willDestroyElement() {
        this._super(...arguments);

        let parentContainer = this.parentContainer;        
        if(parentContainer){
            this.parentContainer.element.removeEventListener('resizestop', this.onResizeStop);
        }    
    },
    onResizeStop (event, element) {
        this.drawChart(element.size.height);                
    },
    didRender(){
        this._super(...arguments);
        
        this.drawChart();
        let parentContainer = this.parentContainer;
        
        if(parentContainer){
            this.parentContainer.element.addEventListener('resizestop', this.onResizeStop);
        }
    }


});
