import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='scalar-visual' style={{visualStyle}}>\n    {{value}}\n</div>", {"contents":"<div class='scalar-visual' style={{visualStyle}}>\n    {{value}}\n</div>","moduleName":"bi/components/report/scalar-visual.hbs","parseOptions":{"srcName":"bi/components/report/scalar-visual.hbs"}});
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import textformatter from '../../utils/textformatter';

import { later, cancel } from '@ember/runloop';
import { observer, computed } from '@ember/object';

export default Component.extend({
    activetabService: service(),
    visualStyle: computed('fontSize', 'fontColor', function() {
        return htmlSafe('font-size: ' + this.fontSize + 'px;color: ' + this.fontColor + ';');
    }),
    didReceiveAttrs() {
        this._super(...arguments);

        let data = this.data;
        let visual = this.visual;

        if(!data || !visual){
            return 
        }

        let value = '-';
        if(data.results && data.results.length > 0){
            let row = data.results[0];

            if(!visual.groups){
                visual.groups = [];
            }
            
            if(row.length > visual.groups.length + 1){
                value = row[visual.groups.length];
            }else{
                value= row[0];
            }
        }

        this.set("initialValue", value);
        this.set('value', textformatter.format(value, visual.values[0].format));

        this.set("fontColor", visual.displayProperties.fontColor);

        this.setFontColorForAlerts(visual, value);

        this.set("fontSize", visual.displayProperties.fontSize);

        if (visual.values[0].format == "time_since") {
            if(!this.runLaterTimer){
                this.incrementCounter()        
            }
        }       

    },
    incrementCounter() {
        if(this.isDestroyed || this.activetabService.isHidden){
            return;
        }
    
        this.set('value', textformatter.format(this.initialValue, "time_since"));

        this.runLaterTimer = later(() => {
            this.incrementCounter();
        }, 500);
    },
    isHiddenWatcher: observer( 'activetabService.isHidden', function() {
        if(this.activetabService.isHidden){
            if(this.runLaterTimer){
                cancel(this.runLaterTimer);                
            }
        }else {
            cancel(this.runLaterTimer);            
            this.incrementCounter();      
        }
       
    }),
    setFontColorForAlerts(visual, value){
        if(visual.alerts && visual.alerts.length > 0) {
            
            visual.alerts.forEach((a)=>{
                if(Number(value) >= Number(a.min) && Number(value) < Number(a.max)){
                    this.set("fontColor", a.color);
                }               
            });
        }
    }
});
